<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 sm8 md8 text-left>
        <span class="mainHeader">Customer List</span>
      
      </v-flex>
      <!-- <v-flex xs12 sm4 md2 text-right>
        <v-autocomplete
                color="#b234a9"
                :items="schemeList"
                v-model="schemefilter"
                background-color="white"
                placeholder="Select Scheme"
                item-text="goldSchemeName"
                item-value="_id"
                outlined clearable
                hide-details
                class="rounded-xl"
              dense
              ></v-autocomplete>
        </v-flex> -->
      <v-flex xs12 sm4 md2 text-right pl-1>
        <v-text-field 
              v-model="keyword"
              clearable
              background-color="white"
              color="#b234a9"
              hide-details
              placeholder="Search"
              outlined
              class="rounded-xl"
              dense
              append-icon="mdi-magnify"
            >
            </v-text-field>
      </v-flex>
      
            <v-flex xs12 sm4 md2 pt-2 pt-sm-0 
                class="buttons1">
              <v-btn dark color="#3F053C" @click="addcustomerdialogue = true">
               <v-icon>mdi-plus</v-icon>Customer
              </v-btn>
            </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4 v-if="customerList">
            <v-flex xs12 v-if="customerList.length>0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left tablefont"><b>S.No.</b></th>
                      <th class="text-left tablefont"><b>Name</b></th>
                      <th class="text-left tablefont"><b>Phone</b></th>
                      <!-- <th class="text-left tablefont"><b>City</b></th>
                      <th class="text-left tablefont"><b>Address</b></th> -->
                      <th class="text-left tablefont"><b>Aadhar No</b></th>
                      <th class="text-left tablefont"><b>PAN</b></th>
                      <!-- <th class="text-left tablefont"><b>Pending Payment(₹)</b></th> -->
                      <th class="text-left tablefont"><b>Balance</b></th>
                      <th class="text-left tablefont"><b>Action</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, i) in customerList" :key="i" class="table">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td>
                        <span v-if="value.customerName ">{{ value.customerName  }}</span>
                        <span v-else>-</span>
                      </td>
                    
                    
                       <td>
                        <span v-if="value.mobileNumber">{{ value.mobileNumber }}</span>
                        <span v-else>-</span>
                      </td>
                      <!-- <td>
                        <span v-if="value.city">{{ value.city }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.address">
                          {{ value.address }}</span
                        >
                        <span v-else>-</span>
                      </td> -->
                      <td>
                        <span v-if="value.aadharNo">
                          {{ value.aadharNo }}</span
                        >
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.panNo">
                          {{ value.panNo }}</span
                        >
                        <span v-else>-</span>
                      </td>
                      <!-- <td>
                        <span v-if="value.customerPendingPayAmount">
                          {{ value.customerPendingPayAmount.toFixed(3) }}</span
                        >
                        <span v-else>-</span>
                      </td> -->
                      <td>
                        <span v-if="value.SchemeMaturedTotalAmount">
                          {{ value.SchemeMaturedTotalAmount.toFixed(3) }}</span
                        >
                        <span v-else>-</span>
                      </td>
                      <!-- <td>
                     
                     <v-btn color="#3F053C"
                        outlined
                        small @click="(addScheme=true),(customerdetails=value.customerName)(customerId=value._id)"><v-icon>mdi-plus</v-icon> Scheme</v-btn>
                    </td> -->
                      <td>
                        <!-- <v-icon
                          size="18"
                          color="#3F053C"
                          style="cursor: pointer"
                          @click="
                            $router.push('/CustomerDetail?id=' + value._id)
                          "
                          >mdi-eye</v-icon
                        > -->
                        <v-icon
                          size="18" color="#3F053C"
                          class="pr-2"
                          style="cursor: pointer"
                          @click="(curItem = value), (editdialogue = true)"
                          >mdi-pencil</v-icon
                        >
                        <v-icon
                          size="18" color="#3F053C"
                          style="cursor: pointer"
                          @click="(deletedialog = true), (curId = value._id)"
                          >mdi-delete</v-icon
                        >
                      </td>
                     
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
            <v-flex xs12 v-else pa-4 text-center class="tablefont1"> No data found </v-flex>
          </v-layout>
          <v-layout wrap v-if="customerList">
            <v-flex xs12 pt-4 v-if="customerList.length>0">
              <v-pagination
                small
                color="#3F053C"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
        <v-dialog width="800px" v-model="addScheme">
            <v-form v-model="addcat" ref="addcat" @submit.prevent>
              <v-card tile width="1000px" class="px-8 py-4 rounded-lg">
                <v-layout wrap py-2>
                  <v-flex xs11 class="subhead3" text-left>ENLISTING</v-flex>
                  <v-flex xs1 text-right
                    ><v-icon @click="(addScheme = false)(resetForm())" color="#3F053C"
                      >mdi-close-box</v-icon
                    ></v-flex
                  >
                </v-layout>
               <v-layout wrap>
                <v-flex xs12 sm6>
                  <v-layout wrap pb-4>
                  <v-flex xs12>
                    <v-layout wrap px-4>
                      <v-flex xs12 text-left>
                        <span class="subhead5"> Customer Name </span>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          :rules="[rules.required]"
                          type="text"
                          v-model="customerdetails"
                          outlined
                          dense
                          color="#b234a9"
                          hide-details="auto"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap px-4>
                      <v-flex xs12 text-left>
                        <span class="subhead5">Yearly Interest</span>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          :rules="[rules.required]"
                          type="Number"
                          placeholder="Enter Yearly Interest"
                          v-model="yrlyintrst"
                          outlined
                          dense disabled
                          color="#b234a9"
                          hide-details="auto"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap px-4>
                      <v-flex xs12 text-left>
                        <span class="subhead5">Start Date</span>
                      </v-flex>
                      <v-flex xs12>
                        <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                       
                        outlined
                        dense
                        hide-details="auto"
                        append-icon="mdi-calendar-multiselect"
                        readonly
                        color="#b234a9"
                        v-model="fromDate"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="fromDate = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fromDate"
                      color="#571964"
                      @change="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-layout wrap pb-4>
                  <v-flex xs12>
                    <v-layout wrap px-4>
                      <v-flex xs12 text-left>
                        <span class="subhead5"> Scheme Name </span>
                      </v-flex>
                      <v-flex xs12>
                        <v-select
                    color="#b234a9"
                    :items="schemeList"
                    v-model="goldSchemeName"
                    placeholder="Select Scheme"
                    item-text="goldSchemeName"
                    item-value="_id"
                    @input="selectThis(goldSchemeName)"
                    outlined
                    hide-details
                    dense
                  ></v-select>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap px-4>
                      <v-flex xs12 text-left>
                        <span class="subhead5"> Monthly Deposit </span>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          :rules="[rules.required]"
                          type="Number"
                          placeholder="Enter Monthly Deposit"
                          v-model="mnthlydpst"
                          outlined
                          dense disabled
                          color="#b234a9"
                          hide-details="auto"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                
                  <v-flex xs12>
                    <v-layout wrap px-4>
                      <v-flex xs12 text-left>
                        <span class="subhead5">Maturity Date</span>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          disabled
                          placeholder="Enter Mature D"
                          v-model="secondDate"
                          outlined
                          dense
                          color="#b234a9"
                          hide-details="auto"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                </v-flex>
               </v-layout>
                <v-layout wrap pb-4 justify-end>
                <v-spacer></v-spacer>
                  <v-flex xs2 text-right>
                    <v-layout wrap px-4 justify-end fill-height>
                      <v-flex xs12 text-center align-self-end>
                        <v-btn
                          type="submit"
                          color="#3F053C"
                class="buttons1"
                          dark 
                          block
                          @click="addToScheme()"
                          ><v-icon>mdi-plus</v-icon>Add</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-form>
          </v-dialog>
        <v-dialog width="800px" v-model="addcustomerdialogue">
          <v-form v-model="addcat" ref="addcat" @submit.prevent>
            <v-card tile width="1000px" class="px-8 py-4 rounded-lg">
             
              <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Add Customer</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="(addcustomerdialogue = false)(resetForm())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
              <v-layout wrap pb-2>
                <v-flex xs6>
                  <v-layout wrap>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont"> Customer Name </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        type="text"
                        placeholder="Enter Full Name"
                        v-model="newCustomer"
                        outlined
                        dense
                        :rules="[rules.required]"
                        color="#b234a9"
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont">PAN Number</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        type="text"
                        placeholder="Enter PAN"
                        v-model="newCustomerPAN"
                        outlined
                        dense
                        :rules="panRules"
                        color="#b234a9"
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont">Aadhar Number</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        type="number"
                        placeholder="Enter Aadhar"
                        v-model="newCustomerAadhar"
                        outlined
                        dense
                        :rules="aadhaarRules"
                        color="#b234a9"
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont"> City </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-flex xs12>
                        <v-text-field
                          color="#b234a9"
                          type="text"
                          placeholder="Enter City"
                          v-model="newCity"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont"> Pin Code </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-flex xs12>
                        <v-text-field
                          color="#b234a9"
                          type="number"
                          placeholder="Enter Pin"
                          v-model="newpinNumber"
                          outlined
                  :rules="pinRules"
                          dense
                          hide-details="auto"
                        ></v-text-field>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-flex>
                </v-layout>
                </v-flex>
                <v-flex xs6>
                  <v-layout wrap>
                    <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont"> Address </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-textarea
                        color="#b234a9"
                        type="text"
                        style="height: 168px;"
                        placeholder="Enter Address"
                        v-model="newAddress"
                        outlined
                        dense
                        hide-details
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="tablefont">Phone Number </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        placeholder="Contact"
                        v-model="newContact"
                        outlined
                        :rules="phoneRules"
                        color="#b234a9"
                        dense
                        type="number"
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
               
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap pb-4 justify-end>
              <v-flex xs3>
                  <v-btn
                    type="submit"
                    color="#3F053C"
                    class="buttons1"
                    dark 
                    block
                    @click="valid()"
                    ><v-icon>mdi-plus</v-icon>Add</v-btn
                  >
                </v-flex>
              </v-layout>
              <!-- <v-btn
                        type="submit"
                        color="#3F053C"
                        class="body-2 font-weight-bold"
                        dark
                        block
                        @click="valid()"
                        ><v-icon>mdi-plus</v-icon>Add</v-btn
                      > -->
            </v-card>
          </v-form>
        </v-dialog>
             <v-dialog width="800px" v-model="editdialogue">
           <v-card width="800px" class="pa-2">
            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Edit Customer</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="editdialogue = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
            <v-layout wrap>
        
             
            <v-flex xs6 px-2>
                <v-layout wrap px-4 py-2>
                  
                <v-flex xs12 text-left class="tablefont">Name</v-flex>
              <v-flex xs12 >

                <v-text-field
                  
                  placeholder="Name"
                  v-model="curItem.customerName"
                  outlined
                  color="#b234a9"
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
            
            </v-layout>
              <v-layout wrap px-4 py-2>
                <v-flex xs12 text-left class="tablefont">City</v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="curItem.city"
                  dense
                  placeholder="City"
                  outlined
                  type="text"
                  color="#b234a9"
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
              <v-layout wrap px-4 py-2>
                <v-flex xs12 text-left class="tablefont">Phone</v-flex>
              <v-flex xs12>
                <v-text-field
                    rows="1"
                  placeholder="Phone"
                  v-model="curItem.mobileNumber"
                  outlined
                  :rules="phoneRules"
                  color="#b234a9"
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap px-4 py-2>
                <v-flex xs12 text-left class="tablefont">Pin Number</v-flex>
              <v-flex xs12>
                <v-text-field
                    rows="1"
                  placeholder="PinCode"
                  v-model="curItem.pinNumber"
                  outlined
                  :rules="pinRules"
                  color="#b234a9"
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-flex>
            </v-layout>
            </v-flex>
                <v-flex xs6 px-2>
                <v-layout wrap px-4 py-2>
                <v-flex xs12  text-left class="tablefont">Address</v-flex>
               <v-flex xs12 >
                <v-text-field
                  v-model="curItem.address"
                  dense
                  color="#b234a9"
                  placeholder="Address"
                  outlined
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap px-4 py-2>
                <v-flex xs12  text-left class="tablefont">Aadhar</v-flex>
               <v-flex xs12 >
                <v-text-field
                  v-model="curItem.aadharNo"
                  dense
                  :rules="aadhaarRules"
                  color="#b234a9"
                  placeholder="Aadhar"
                  outlined
                        hide-details="auto"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap px-4 py-2>
                <v-flex xs12  text-left class="tablefont">PAN</v-flex>
               <v-flex xs12 >
                <v-text-field
                  v-model="curItem.panNo"
                  dense
                  :rules="panRules"
                  color="#b234a9"
                  placeholder="PAN"
                  outlined
                        hide-details="auto"
                ></v-text-field>
              </v-flex>
            </v-layout>
            </v-flex>
          </v-layout>
          
            <v-card-actions class="pt-3">
              <!-- <v-btn
                color="grey"
                text
              class="body-2 font-weight-bold"
                @click="editdialogue = false"
                >Cancel</v-btn
              > -->
              <v-layout wrap>
              <v-spacer></v-spacer>
                <v-flex xs2>
               <v-btn
                color="#3F053C"
                class="buttons1"
                dark block
                @click="editCustomer()"
                >OK</v-btn
              >
            </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
            <v-dialog width="400px" v-model="deletedialog">
        <v-card width="400px" class="pa-2">
         

            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Delete</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="deletedialog = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
          <v-card-text class="pa-4 tablefont"
            >Are you sure you want to delete this customer?
          </v-card-text>
          <v-card-actions class="pt-3">
            <!-- <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="deletedialog = false"
              >Cancel</v-btn
            > -->
            <v-layout wrap>
              <v-spacer></v-spacer>
                <v-flex xs3>
                  <v-btn
            color="#3F053C"
            class="buttons1"
              dark block
              @click="deleteCustomer()"
              >OK</v-btn
            >
            </v-flex>
              </v-layout>
           
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      page:1,
      Pagelength:null,
      jewelleryId: null,
      adminList: [],
      rolesList: [],
      customerName : null,
      address: null,
      email: null,
      phoneNumber: null,
      newCustomer: null,
      newCustomerPAN: null,
      newCustomerAadhar: null,
      newAddress: null,
      newCity: null,
      newContact: null,
      newpinNumber: null,
      addcustomerdialogue: false,
      addcat:false,
      positionList: null,
      customerList:[],
      curItem:[],
      curId:null,
      customerdetails:"",
      schemeList:[],
      keyword:"",
      schemefilter:"",
      selected:[],
      yrlyintrst:"",
      mnthlydpst:"",
      menu1:false,
      fromDate:"",
      secondDate:"",
      goldSchemeName:"",
      monthlyDeposit:"",
      customerId:"",
      addScheme:false,
       editdialogue:false,
deletedialog:false,
rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      phoneRules: [
        (v) => !!v || "phone is required",
        (v) => (v && v.length <= 10) || "invalid number",
        (v) => (v && v.length >= 10) || "invalid number",
        (v) => /^\d{10}$/.test(v) || "phone number must be a number",
      ],
      aadhaarRules: [
  (value) => !!value || "Aadhaar number is required.",
  (value) => /^\d{12}$/.test(value) || "Invalid Aadhaar number.",
],
      panRules: [
  (value) => !!value || "PAN number is required.",
  (value) => /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value) || "Invalid PAN number.",
],
pinRules: [
    (value) => !!value || "PIN number is required.",
    (value) => /^\d{6}$/.test(value) || "Invalid PIN number.",
  ],
// aadhaarRules: [
//   (value) => !!value || "Aadhaar number is required.",
//   (value) => /^\d{12}$/.test(value) || "Invalid Aadhaar number.",
// ],
    };
  },
  mounted() {
    this.getCustomer();
    this.getSchemeList();
  },
  watch:{
    keyword(){
      this.getCustomer();
    },
    schemefilter(){
      this.getCustomer();
    },
    page(){
    this.getCustomer();
  },
  fromDate(newValue) {
      if (newValue) {
        const selectedDate = new Date(newValue);
        const futureDate = new Date(selectedDate.setMonth(selectedDate.getMonth() + 12));
        this.secondDate = futureDate.toISOString().slice(0, 10);
        console.log(this.secondDate,"==this.secondDate")
      } else {
        this.secondDate = null;
      }
    }
  },
 
  computed: {
    minDate() {
      if (this.fromDate) {
        const selectedDate = new Date(this.fromDate);
        selectedDate.setMonth(selectedDate.getMonth() + 12);
        return selectedDate.toISOString().slice(0, 10);
      } else {
        return null;
      }
    },
    appUser() {
      return this.$store.state.userData;
    },
  },
  methods: {
    resetForm() {
      this.$refs.addcat.reset();
    },
    
    valid(){
      if (!this.newCustomer) {
        this.msg = "Please enter customer name";
        this.showsnackbar = true;
      } else if (!this.newCustomerPAN) {
        this.msg = "Please enter PAN ";
        this.showsnackbar = true;
      } 
      else if (!this.newCustomerAadhar) {
        this.msg = "Please enter aadhar no.";
        this.showsnackbar = true;
      }else if (!this.newContact) {
        this.msg = "Please enter contact";
        this.showsnackbar = true;
      } else {
        
        this.AddCustomer();
      }
    },
    selectThis(id) {
      console.log("id==",id);
        this.selected = this.schemeList.filter((x) => x._id == id);
        this.yrlyintrst=this.selected[0].yearlyIntrest;
      this.mnthlydpst=this.selected[0].minmonthlydeposit;
      console.log("yrlyintrst==", this.yrlyintrst);
      console.log("mnthlydpst==", this.mnthlydpst);
      console.log("selected==", this.selected);

    },
    getCustomer() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/customer/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          limit: 20,
          keyword:this.keyword,
          schemeName:this.schemefilter,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.customerList = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
   
    AddCustomer() {
      axios({
        url: "/customer/add",
        method: "POST",
        data: {
          customerName: this.newCustomer,
      panNo: this.newCustomerPAN,
          aadharNo: this.newCustomerAadhar,
          address: this.newAddress,
      city: this.newCity,
      mobileNumber: this.newContact,
      pinNumber: this.newpinNumber,
      jewelleryId:this.juwellaryId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.addcustomerdialogue=false;
            this.getCustomer();
            this.cid=response.data.customerData._id;
            // customerdetails
            // this.customerName=this.newCustomer;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.newCustomer = null;
            this.newpinNumber = null;
            this.newCustomerPAN = null;
            this.newCustomerAadhar = null;
            this.newAddress = null;
            this.newCity = null;
            this.newContact = null;
            this.$refs.addcat.resetValidation();

          } else {
            this.datareload=false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editCustomer(){
      axios({
        url: "/customer/edit",
        method: "POST",
        headers: {
         "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id:this.curItem._id,
          mobileNumber: this.curItem.mobileNumber,
          customerName  : this.curItem.customerName,
          city: this.curItem.city,
          aadharNo: this.curItem.aadharNo,
          panNo: this.curItem.panNo,
          address: this.curItem.address,
          pinNumber: this.curItem.pinNumber,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getCustomer();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getCustomer();

          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
      deleteCustomer() {
      axios({
        url: "/customer/delete",
        method: "GET",
        headers: {
            "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.deletedialog = false;
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getCustomer();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSchemeList() {
        // this.appLoading = true;
        axios({
          method: "get",
          url: "/goldscheme/getlist",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            keyword: this.keyword,
            page: this.page,
            count: 20,
          },
        })
          .then((response) => {
            // this.appLoading = false;
            if (response.data.status == true) {
              this.schemeList = response.data.data;
              this.Pagelength = Math.ceil(response.data.count / 20);
            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
            }
  
            // this.msg = response.data.msg;
  
            // this.snackbar = true;
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
      addToScheme(){
      axios({
        url: "/goldscheme/customer/add",
        method: "POST",
        headers: {
         "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          customerId: this.customerId,
          goldSchemeId  : this.goldSchemeName,
          matureDate: this.secondDate,
          startDate: this.fromDate,
        },
      })
        .then((response) => {
          
          this.appLoading = false;
          if (response.data.status==true) {
            this.addScheme = false;
            this.customerdetails="";
            this.yrlyintrst="";
            this.fromDate="";
            this.goldSchemeName="";
            this.mnthlydpst="";
            this.secondDate="";
            this.msg = response.data.msg;
            this.showsnackbar = true;
            // this.getCustomer();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            // this.getCustomer();

          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.dialog-position {
  right: 0;
  margin-right: 20px; /* Optional: Add some margin for spacing */
}
</style>
